import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import projectsJs from '../../const/projects';
import Layout from '../../layout';
import _ from 'lodash';
import CarouselMobile from '../../components/CarouselMobile';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Astor from '../../images/Astor.png';
import Bmymotel from '../../images/Bmymotel.png';
import Becquer from '../../images/Becquer.png';
import Diana from '../../images/Diana.png';
import Kuboz from '../../images/Kuboz.png';
import Pinos from '../../images/Lospinos.png';
import Mirage from '../../images/Mirage.png';
import MonteReal from '../../images/MonteReal.png';
import Montebello from '../../images/Montebello.png';
import Olimpo from '../../images/Olimpo.png';
import Otay from '../../images/Otay.png';
import PM from '../../images/Kuboz.png';
import RealRio from '../../images/Realdelrio.png';
import Real from '../../images/Real.png';
import Square from '../../images/squareLogo.png';
import Virreyes from '../../images/Virreyes.png';
import ZentMotel from '../../images/ZentMotel.png';
import decameron from '../../images/decameron.png';
import paradisevillage from '../../images/paradisevillage.png';
import monide from '../../images/monide.png';

const useStyles = makeStyles((theme) => (
    {
        heightMobile:{
            height:'auto',
            paddingBottom:'2rem',
        },
        desktop:{
            [theme.breakpoints.down('sm')]:{
                display: 'none',
            }
        },
        mobile:{
            display:'none',
            [theme.breakpoints.down('sm')]:{
                display: 'block',
            }
        },
        title:{
            color:'#003A7A',
            textTransform:'uppercase',
            fontFamily:'Futura Now Headline Extra Bold Italic',
            fontSize:'16pt'
        },
        containerImg:{
            maxWidth:'200px',
            height: '150px',
            display:'grid !important',
            '& img':{
                margin:'auto',
                '&.img-80':{
                    width: '60%'
                }
            }
        }
    }
))

const Proyectos = () => {
    const [ projects, setProjects ] = useState(projectsJs);
    const classes = useStyles();
    const [isMobile, setMobile] = useState(6);
    const setting = {
        className: "center",
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
      };

    useLayoutEffect(()=>{
        setMobile(document.documentElement.clientWidth <= 769 ? 1 : 6);
        
    },[]);

    const handleImg = (id) => {
        let activeIndex = _.findIndex(projects,(project) => project.isActive === true);
        let index = _.findIndex(projects,(project) => project.id === id);
        let temp = [...projects];
        temp[activeIndex] = { ...temp[activeIndex], isActive:false };
        temp[index] = { ...temp[index], isActive: !temp[index].isActive }; //187.190.125.78 Aguas <- 187.190.63.104 Cancun X 189.203.74.251 tepic <-
        setProjects([...temp]);
    }
    return (
        <Layout footer>
            <Container className={classes.heightMobile}>
                <Carousel
                title="Proyectos"
                className={classes.desktop}
                mainImg={projects.filter((project) => project.isActive)[0]} 
                onClick={handleImg} 
                imgs={projects.filter((project) => !project.isActive)} />
                <CarouselMobile
                    title="Proyectos"
                    className={classes.mobile}
                    imgs={projectsJs}
                />
                <Grid container>
                    <Grid item xs={12} className="text-center">
                        <h1 className={classes.title}>colaboración de marcas</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <Slider {...setting}>
                            <div className={classes.containerImg}>
                                <img src={Astor} width="100%" alt="astor"/>
                            </div>
                            <div className={classes.containerImg} >
                                <img src={Bmymotel} className="img-80" alt="bmyhotel"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Becquer} className="img-80" alt="becquer"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Diana} className="img-80" alt="diana"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Kuboz} className="img-80" alt="kuboz"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Pinos} className="img-80" alt="pinos"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Mirage} className="img-80" alt="mirage"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={MonteReal} width="80%" alt="mote real"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Montebello} className="img-80" alt="monte bello"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Olimpo} className="img-80" alt="Olimpo"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Otay} className="img-80" alt="Otay"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={PM} className="img-80" alt="PM"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={RealRio} className="img-80" alt="Real Rio"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Real} className="img-80" alt="Real"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Square} width="80%" alt="square"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={Virreyes} className="img-80" alt="virreyes"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={ZentMotel} className="img-80" alt="Zent Motel"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={decameron} className="img-80" alt="Decameron"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={monide} className="img-80" alt="Monide"/>
                            </div>
                            <div className={classes.containerImg}>
                                <img src={paradisevillage} className="img-80" alt="Paradise Village"/>
                            </div>
                        </Slider>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default Proyectos;