 import bequer from '../images/bequer.jpg';
 import otani from '../images/otani.jpg';
 import sadao from '../images/sadao1.jpg';
 import square from '../images/square.png';

 const projects = [
    {
        id:1,
        title:'HOTEL BECQUER “GDL”',
        description:`Trabajando de la mano con “MON IDÉE INTERIOR ESTUDIO” se desarrolló este proyecto inspirado en el poeta, narrador y artista español Gustavo Adolfo Bécquer, el cual arrancó en el año 2020 y se encuentra situado en una ubicación excelente para visitar la hermosa ciudad de Guadalajara. 
        Este proyecto es el claro ejemplo del trabajo en equipo de dos empresas, DIBLAGA y MON IDÉE INTERIOR ESTUDIO, para lograr desarrollar hermosos espacios con la elegancia y calidad deseada. 
        `,
        src:bequer,
        isActive:true,
    },
    {
        id:2,
        title:'HOTEL SQUARE',
        subtitle:'',
        description:`"Trabajar con Blancos DIBLAGA ha sido una gran experiencia, hemos contado con un gran proveedor, generando una gran productividad y confianza, gracias a la calidad y atención por parte de ellos. Los recomiendo ampliamente.”"`,
        src:square,
        isActive:false,
    },
    {
        id:3,
        title:'HOTEL SADAO "Monterrey"',
        description:`"Algo que caracteriza a DIBLAGA, es la personalización de nuestros productos. En este proyecto se buscaron detalles específicos en blancos y cortinería para lograr el confort necesario y generar el ambiente idóneo para relajarse y pasar un excelente momento, siendo un hotel con un concepto muy especial logrando ser caótico y hermoso a la vez."`,
        src:sadao,
        isActive:false,
    },
    {
        id:4,
        title:'OTANI MOTEL BOUTIQUE “LEÓN”',
        description:"Este 2020 nos brindó grandes proyectos y el Motel OTANI es uno de ellos, consiguiendo despertar los sentidos de los huéspedes mediante nuestras prendas y a la vez satisfacer al cliente en tiempos de entrega y calidad de nuestras prendas, brindando una mezcla de detalles para lograr este excelente proyecto.",
        src:otani,
        isActive:false,
    },
];
 export default projects;